import React, {useEffect, useState} from "react";
import Error from "./Error";
import '../css/town.css';
import Hero from "../Component/Hero";
import TownBeachMaps from "../Component/TownBeachMaps";
import { useParams } from 'react-router-dom';
import {capeTowns} from '../data/towns';

const Towns= () => {
    const [beachNorthList, setBeachNorthList] = useState([]); 
    const [beachSouthList, setBeachSouthList] = useState([]); 
    const [townBeaches, setTownBeaches] = useState([]);
    useEffect(() => {
        fetch("https://o8bes3ddv2.execute-api.us-east-1.amazonaws.com/staging/beach", {
                "method": "GET",
            })
            .then(response => response.json())
            .then(response => {
                setTownBeaches(response);
                response.forEach((beach) => { 

                    console.log(beach.cape_side);
                    if(beach.cape_side === "North Side"){
                        console.log("Add north");
                        setBeachNorthList(currentList => [...currentList, beach.name]);
                    }else if(beach.cape_side === "South Side") {
                        console.log("Add south");
                        setBeachSouthList(currentList => [...currentList, beach.name]);
                    }
                });
                console.log(townBeaches);
            });
    },[]);
    const { town  } = useParams();
    const details = capeTowns.find(element => element.name === town);
    if(!details){
       return <Error/>; 
    }

    var icon = `/town-seal/micro/${details.icon}`;
        console.log("----------------------------");
        console.log(beachNorthList);
        console.log("----------------------------");
    return (
    <>
    <div className="town-page-background-image">
        <div className="town-page-row">
            <div className="town-padding-color">  
                <div className="town-box "> 
                    <div className="town-top-column">

                        <h1>Welcome To The Town Of {details.name}</h1> 
                        <img src={icon}/>

                    <p>
                        A super long paragraph of info.
                        A super long paragraph of info.
                        A super long paragraph of info.
                        A super long paragraph of info.
                        A super long paragraph of info.
                        A super long paragraph of info.
                        A super long paragraph of info.
                        A super long paragraph of info.
                        A super long paragraph of info.
                        A super long paragraph of info.
                        A super long paragraph of info.
                        A super long paragraph of info.
                    </p>

                    </div>

                </div>
            </div>
            <div className="town-page-row">
                    <div className="town-column-beach">  
                            <TownBeachMaps town={details}/>

                    </div>
                    <div className="town-column-beach-name">
                            <ul className="" style={{height:'90%',  overflow: 'auto' }}>
                                  <li>
                                    <h2>----North Side---</h2>


                                        <ul><br/></ul>
                                        <ul>
                                            { 
                                            beachNorthList.map((beachName) => { 
                                                const beachPath = `/${town}/${beachName}`;

                                                return  <h3><a href={beachPath   } >{beachName}</a></h3>
                                            })
                                            }
                                    </ul>
                                  </li>
                                  <li>

                                        <ul><br/></ul>
                                    <h2>---South Side----</h2>
                                        <ul><br/></ul>
                                        <ul>
                                            { 
                                            beachSouthList.map((beachName) => 
                                                <p>{beachName}</p>)
                                            }

                                        </ul>

                                  </li>
                            </ul>
                    </div>
                </div>
        </div>

    </div>     

    </>
    );
}
export default Towns;