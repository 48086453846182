
import '../css/map.css';
import React, {useEffect} from "react";
import Hero from "../Component/Hero";
import BeachMaps from "../Component/BeachMaps";
import Carousel from '../Component/Carousel';
import BeachConvert from '../Component/BeachConvert';
const Landing = ({page_size}) => {

    return (
    <>
        <Hero/>
        <div className='map-shape'>
            <BeachMaps/>
       </div>
       <Carousel/>
    </>
    );
}
export default Landing;
