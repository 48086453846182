import '../css/join-about-box.css';
import React from "react";
const BottomNav = () => {

    return  (
        <>
        <div className="bottomnav-box">
            <div className="join-about-box">
                <div className="join-about-column-left">
                    <img className="icon-image" src="/icon/icon-starter.png" alt="Cape Cod Beaches App"  />
                </div>

                <div className="join-about-column-center">
                </div>

                <div className="join-about-column-right">
                    <fieldset className="join-about-column-right-form">
                        <legend>Jump In</legend>
                        <form>
                            <input type="text" id="email" name="email" placeholder="Your email."/>
                        </form>
                        <button>Go--&gt;</button>
                    </fieldset>  
                </div>
            </div>
</div>
    </>
    );
}

export default BottomNav;