import '../css/town-beach-map.css'
import React, {useEffect} from "react";
import { Loader} from "@googlemaps/js-api-loader";
import umbrella from "../logo192.png";
import Carousel from './Carousel';
import {capeTowns} from '../data/towns';

const TownBeachMaps = ({town, page_size}) => {
    useEffect(() => { 

      const pageWidth = document.documentElement.clientWidth
       let mapCenter = {lat:town.lat , lng:town.lng };
       let zoomin = 13;
       if(pageWidth < 420){
          zoomin = 13;
          let mapCenter = {lat:town.lat , lng:town.lng };
       } else if(pageWidth < 680){
          let mapCenter = {lat:town.lat , lng:town.lng };
       } 

       fetch("https://o8bes3ddv2.execute-api.us-east-1.amazonaws.com/staging/beach", {
            "method": "GET",
        })
        .then(response => response.json())
        .then(response => {
            const mapOptions = {
                center: mapCenter, // Set the initial center of the map
                zoom: zoomin, // Set the initial zoom level
                streetViewControl: false,
                mapTypeControl: false,

            };

            const loader = new Loader({
                apiKey: "AIzaSyCIAXaRBlA1a4A-A9GKY60-xFMz3334iGU",
                version: "3.55",
                libraries: ['maps'],

            });


            //console.log(loader.importLibrary('maps'));
            loader.importLibrary('maps')
                .then(({Map, StyledMapType, InfoWindow}) => {
                    //Section includes styles 
                    const mapStyle = new StyledMapType([
                        {
                            "featureType": "poi",
                            "elementType": "all",
                            "stylers": [{ "visibility": "off" }]
                        }
                    ], { name: "beachtype" });
                    //This will set the div id="map" now
                    const beachesMap = new Map(document.getElementById("beachesmap"), mapOptions);
                    beachesMap.mapTypes.set('beachtype', mapStyle);
                    beachesMap.setMapTypeId("beachtype");

                    var beachrow = -1;
                    //Add markers from the above new Map added 
                    loader.importLibrary("marker")
                      .then(({Marker})=> {
                        response.forEach((beach) => {
                            //Adding points for each area
                            if(beachrow < capeTowns.length ){
                              beachrow = beachrow + 1
                              const county = capeTowns[beachrow];
                              const beachPosition =  {lat:county.lat, lng:county.lng, };
                              const marker = new Marker({
                                    position: beachPosition,
                                    optimized: true,
                                    beachesMap,
                              })

                                  const tooltipContent = `<a href="/${county.name}">${county.name}</a>`;
                                  const infoWindow = new InfoWindow({
                                    content: tooltipContent,
                                  });
                                  marker.addListener('mouseover', () => {
                                     infoWindow.open(beachesMap, marker);
                                  });
    
                                  marker.addListener('mouseout', () => {
                                    infoWindow.close();
                                  });

                                  marker.setMap(beachesMap);
                            }

                            //Adding pints for each beach
                            const beachPosition =  {lat:beach.location.latitude, lng:beach.location.longitude };
                            const umbrellaIcon = {
                              url: umbrella,
                              scaledSize: new window.google.maps.Size(32,35),
                            };

                            const marker = new Marker({
                              position: beachPosition,
                              beachesMap,
                              icon: umbrellaIcon,
                              optimized: true,
                            })
                            const path = beach.name.replace(/ /g, '-');
                            const tooltipContent = `<a href="/${path}">${beach.name}</a>`;
                            const infoWindow = new InfoWindow({
                              content: tooltipContent,
                            });
                            marker.addListener('mouseover', () => {
                               infoWindow.open(beachesMap, marker);
                            });
    
                            marker.addListener('mouseout', () => {
                              infoWindow.close();
                            });

                           marker.setMap(beachesMap);

                        });


                    })
                    .catch((e) => {
                        // do something
                    });


                })
                .catch((e) => {
                        // do something
                });


          });


 
    }, []);   

    return (
    <>
    <div className="map-shape  " id="beachesmap" style={{ width: '50%', height: '100vh' }} ></div>

    </>
    );

}


export default TownBeachMaps;