import React from "react";

const CarouselItem = ({ town, width_height}) => {

    var path = `/${town.name}`;
    var icon = `/town-seal/${town.icon}`;
    return (
        <div className="carousel-item"> 
            <div className="carousel-item-text">
                <a href={path}>
                <img 
                className=" " 
                style={{ 
                    width: width_height,
                    height: width_height,
                    padding: "10px",
                }}
                src={icon}/>
                {town.name}
                </a>
            </div>
        </div>
    );
}

export default CarouselItem;