import React, {useState} from 'react';
import '../css/topnav.css';
const TopNav = () => {

    const [toggle, setToggle] = useState(false);
    const handleClick = ()=> setToggle(!toggle);
    return (
        <div>
                <div className='w-full h-[90px] topnav-padding-color border-b-2 border-oj  ' >
                <div className='md:max-w-[1480px] max-w-[600px] m-auto w-full h-full flex justify-between items-center' >
                    <a href="/">
                    <img src='/icon/icon-starter.png' className="h-[75px] " alt=""/>
                    </a>
                    <div className='hidden md:flex items-center '>
                        <ul className='flex gap-4'>
                            <li className='cape-font-large topnav-text-color '>&lt;-- Let's Go To The Beach --&gt; </li> 
                        </ul>
                    </div>

                     <div className='md:hidden items-center text-center '>
                            <h3 className='cape-font-small topnav-text-color '>Go To The </h3> 
                            <h3 className='cape-font-small topnav-text-color '>&lt;-- Beach --&gt; </h3> 
                    </div>

                    <div className='hidden md:flex'>
                        <button className='px-8 py-3 rounded-md  topnav-text-color-solid cape-font-med '>
                            <a href="/About" >Learn About Us !</a>
                        </button>
                    </div>
                    
                    <div className='md:hidden ' onClick={handleClick}>
                            <img className='h-[70px]' src={toggle?"icon/burgerbuttontoptobottom.png":"icon/burgerbutton.png"} />
                    </div>
                </div>

            </div>
            <div className={toggle?'rounded-b-lg  topnav-hover  md:hidden':'hidden'}>
                <div className="p-4 leading-normal ">
                    <ul>
                        <li >
                            <button className='topnav-text-color cape-font-med'>
                                <a href="/About">Learn About Us !</a>
                           </button>
                        </li>
                    </ul>
                </div>
            </div>


    </div>
    )

}
export default TopNav;