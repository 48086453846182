import React, {useEffect, useRef} from "react";
import '../css/about.css';
import '../css/julie.css';

const JuliThree = () => {
    const vid = useRef(null);
    var foo = document.getElementById("vid");
    vid.currentTime = 30; 

    return (
    <>
    <div className="julie-page-background-image">
        <div className="julie-padding-color ">  
                <div className="julie-box align-middle"> 
                    <h2>Shark Tank One</h2> 
                    <video  id="vid" width="800" controls autoPlay="true" ref={vid}>
                        <source src="https://s3.us-east-1.amazonaws.com/capecodbeachesapp.com/S16E3.mov" type="video/mp4"/>
                    </video>
                    <h4>
                        <br/>
                             
                        <br/>
                    </h4>
                </div>
        </div>
        <div className="julie-padding-color ">  
                <div className="julie-box align-middle"> 
                    <h2>Shark Tank Two</h2> 
                    <video  id="vid" width="800" controls autoPlay="true" ref={vid}>
                        <source src="https://s3.us-east-1.amazonaws.com/capecodbeachesapp.com/S15E3-2.mov" type="video/mp4"/>
                    </video>
                    <h4>
                        <br/>

                        <br/>
                    </h4>
                </div>
        </div>
        <div className="julie-padding-color ">  
                <div className="julie-box align-middle"> 
                    <h2>Shark Tank Three</h2> 
                    <video  id="vid" width="800" controls autoPlay="true" ref={vid}>
			<source src="https://s3.us-east-1.amazonaws.com/capecodbeachesapp.com/S16E3-3.mov"	 type="video/mp4"/>    		

                    </video>
                    <h4>
                        <br/>
                       <br/>
                    </h4>
                </div>
        </div>

    </div>     
    </>
    );
}
export default JuliThree;
