import '../css/error.css';
import React, {useEffect} from "react";

const Error = () => {

    return (
    <>
     <div className="error-page-background-image">
        <div className="hero-padding-color ">  
            <a href="/">
                <div className="hero-box align-middle"> 
                    <h2>Woops!!!</h2> 
                    <h4>&lt;--  Time To Go Home --&gt;</h4>
                    <h2></h2>
                    <h3>Cape Cod Beaches App</h3>
                </div>
            </a>
        </div>
    </div>     
    </>
    );
}
export default Error; 
