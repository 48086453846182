import React, {useEffect} from "react";
import '../css/about.css';
import Hero from "../Component/Hero";
import BeachMaps from "../Component/BeachMaps";

const About = ({page_size}) => {

    return (
    <>
    <div className="about-page-background-image">
        <div className="about-padding-color ">  
                <div className="about-box align-middle"> 
                    <h1>About Us</h1> 
                    <p>
                        This is kinda empty. See the big empty section at the 
                        bottom of this box. This is Because I need to spend 
                        more time letting you know about our history, where 
                        we are from, and why we love the beach. We have a lot 
                        of work to make the website running! 
                        So, let's call the "About" page more of a TODO list. 
                        It will be obvious when we have this awesome site up and running.
                    </p>
                    <p>
                        The good news is. The spacing between each \p tag will add nice spacing. 
                        See, we are in the second paragraph. Notice the spacing above here. 
                        That is the ending of the first paragraph. 
                        Cool right! We need to write some multi-paragraph goodness.
                    </p>
                </div>
        </div>
    </div>     
    </>
    );
}
export default About;