import './App.css';
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes, useParams } from 'react-router-dom';

import TopNav from './Component/TopNav';
import Landing from './Page/Landing';
import About from './Page/About';
import Julie from './Page/Julie';
import JulieNew from './Page/JulieNew';
import JulieThree from './Page/JulieThree';
import Towns from './Page/Towns';
import Error from './Page/Error';
import BottomNav from './Component/BottomNav';
import './fonts/ShadowsIntoLight-Regular.ttf';
import BeachConvert from './Component/BeachConvert';
function App() {
  return (
   <BrowserRouter>
        <TopNav/>
            <Routes>
               <Route path="/" element={<Landing/>} />
               <Route path="/About" element={<About/>} />
               <Route path="/:town" element={<Towns/>} />
               <Route path="/julie" element={<Julie/>} />
               <Route path="/julieNew" element={<JulieNew/>} />
               <Route path="/julieThree" element={<JulieThree/>} />
               <Route path="/*" element={<Error/>} />
            </Routes>

          <BottomNav/>
  </BrowserRouter>
  );
}

export default App;
