import "../css/carousel.css";
import React, { useState } from "react";
import CarouselItem from "./CarouselItem";
import {capeTowns} from '../data/towns';

const Carousel = () => {
    const [activeIndex, setActiveIndex] = useState(1);
    const [gap, setGap] = useState(0);
    const [rightOrReturn, setRightOrReturn] = useState("carousel-button-right");
    const [rightOrReturnValue, setRightOrReturnValue ] = useState(1);

    var image_width_height = window.innerWidth;
    var width_count = 1;     
    if (image_width_height >= 1700){
        width_count = 7;
    } else if (image_width_height >= 1400){
        width_count = 6;
    } else if (image_width_height >= 1200){
        width_count = 5;
    } else if (image_width_height >= 800){
        width_count = 4;
    } else if(image_width_height >= 525 ){
        width_count = 4;
    }
    image_width_height = image_width_height / width_count;

    const updateIndex = (nextIndex) => {
        var maxGapCount = Math.round(capeTowns.length / width_count);
        if( nextIndex < activeIndex ) {
            if(nextIndex > 0 ){
                setRightOrReturnValue(1);
                setRightOrReturn("carousel-button-right");
                setActiveIndex(nextIndex)
                setGap(gap - 100);
            }
        } else if (nextIndex < maxGapCount) {
            setActiveIndex(nextIndex)
            setGap(gap + 100);
        } else if (nextIndex >= maxGapCount) {
            setActiveIndex(nextIndex)
            setRightOrReturnValue(-1);
            setRightOrReturn("carousel-button-left");
            setGap(gap + 100);
        }
    };

    window.onresize = function(event) {
        //resizeit();
    };

    const runCarouselItem = capeTowns.map((town) => 
            <CarouselItem town={town} width_height={image_width_height + "px"} />
    );

    const resizeit = () => {
        return runCarouselItem;
    }
    return (
        <div className="carousel">
            <div
                className="inner"
                style={{ transform: `translate(-${gap }%)`
                }}>
                {
                   resizeit() 
                }
            </div>

            <div className="carousel-buttons ">
                    <div className="carousel-column">
                        <button 
                            className="button-arrow" 
                            onClick={() =>{
                            updateIndex(activeIndex - 1)
                         }} >
                            <span className="carousel-button-left"></span> 
                        </button>
                    </div>
                    <div className="carousel-column">
                        <div className="indicators">
                            <h3 className="carousel-item-text">Towns Around</h3>
                        </div>
                    </div>
                    <div className="carousel-column">
                        <button  onClick={() =>{
                                    updateIndex(activeIndex +  rightOrReturnValue)
                            }} 
                            className="button-arrow">
                            <span className={rightOrReturn}></span> 
                        </button>
                    </div>
                </div>

        </div>
    );
}

export default Carousel;