import React, {useEffect, useRef} from "react";
import '../css/about.css';

const Julie = () => {
    const vid = useRef(null);
    var foo = document.getElementById("vid");
    vid.currentTime = 30; 

    return (
    <>
    <div className="about-page-background-image">
        <div className="about-padding-color ">  
                <div className="about-box align-middle"> 
                    <h2>Hey Julie</h2> 
                    <video  id="vid" width="500" controls autoPlay="true" ref={vid}>
                        <source src="https://s3.us-east-1.amazonaws.com/capecodbeachesapp.com/Quantam.mov" type="video/mp4"/>
                    </video>
                    <h4>
                        <br/>
                        Here we go. I recorded a video I watched and my description afterward.
                        You can skip the video to 12:00 for my response.
                        Below is the link to the YouTube video I was watching.
                        <br/>
                       <a href="https://www.youtube.com/watch?v=qQviI1d_hFA"><h3>--Quantum computing--</h3></a>
                    </h4>

                
                </div>
        </div>
    </div>     
    </>
    );
}
export default Julie;