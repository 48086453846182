export const capeTowns = [
    {name: 'Barnstable', lat:41.702, lng:-70.3021284, description:'Barnstable',         icon: "barnstable.png"}, 
    {name: 'Bourne', lat:41.744, lng:-70.6, description:'Bourne',                       icon: "bourne.png"},
    {name: 'Brewster', lat:41.764, lng:-70.08, description:'Brewster',                  icon: "brewster.png"},
    {name: 'Chatham', lat:41.687, lng:-69.96, description:'Chatham',                    icon: "chatham.png"},
    {name: 'Dennis', lat:41.7028695, lng:-70.1505926, description:'Town Of Dennis',     icon: "dennis.png"},
    {name: 'Eastham', lat:41.833, lng:-69.974, description:'Eastham',                   icon: "eastham.png"},
    {name: 'Falmouth', lat:41.556245, lng:-70.6099955, description:'Falmouth',          icon: "falmouth.png"},
    {name: 'Harwich', lat:41.676, lng:-70.06, description:'Harwich',                    icon: "harwich.png" },
    {name: 'Mashpee', lat:41.652, lng:-70.479, description:'Mashpee',                   icon: "mashpee.png"},
    {name: 'Orleans', lat:41.792, lng:-69.99, description:'Orleans',                    icon: "orleans.png"},
    {name: 'Provincetown', lat:42.0563071, lng:-70.1860, description:'Provincetown',    icon: "provincetown.png"},
    {name: 'Sandwich', lat:41.761, lng:-70.49, description:'Sandwich',                  icon: "sandwich.png"},
    {name: 'Truro', lat:42.0061905, lng:-70.05418, description:'Truro',                 icon: "truro.png"},
    {name: 'Wellfleet', lat:41.935, lng:-70.0299705, description:'Wellfleet',           icon: "wellfleet.png"},
    {name: 'Yarmouth', lat:41.71, lng:-70.23, description:'Yarmouth',                   icon: "yarmouth.png"}
  ];